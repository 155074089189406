import { graphql } from 'gatsby';
import React from 'react';

import { Layout, SEO } from '../../components';

const PrivacyPolicy = ({ data: { allMarkdownRemark } }) => {
  const privacyPolicy = allMarkdownRemark.edges[0];

  return (
    <Layout bodyClass="standard-layout text-justify">
      <SEO title="YOU are safe online's privacy policy" url="privacy-policy" />

      <section className="header-section bg-black">
        <div className="container">
          <h1>Privacy policy</h1>
        </div>
      </section>

      <div className="container">
        <section
          className="body-section"
          dangerouslySetInnerHTML={{ __html: privacyPolicy.node.html }}
        />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query PrivacyPolicyQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/privacy-policy/" } }
    ) {
      edges {
        node {
          html
        }
      }
    }
  }
`;

export default PrivacyPolicy;
